
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React from 'react';
import '@/styles/theme/globals.scss';
import { AppProps } from 'next/app';
import FramerProvider from '@/providers/FramerProvider';
import Seo from '@/components/core/Seo';
import { MetadataProps } from '@/components/core/Seo/props';
function App({ Component, pageProps }: AppProps) {
    return (<main>
      <FramerProvider>
        {!!pageProps?.metadata && (<Seo metadata={pageProps?.metadata as MetadataProps}/>)}
        <Component {...pageProps} suppressHydrationWarning/>
      </FramerProvider>
    </main>);
}
const __Next_Translate__Page__1927d47dd3d__ = App;

    export default __appWithI18n(__Next_Translate__Page__1927d47dd3d__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  